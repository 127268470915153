<script setup>
import { loadLanguageAsync, getActiveLanguage, trans } from "laravel-vue-i18n";
import { onMounted, ref } from "vue";
defineProps({
  label: String,
  required: {
    type: Boolean,
    defult: false,
  },
  type: {
    type: String,
    defult: "text",
  },
  modelValue: String,
  lableFor: String,
});

defineEmits(["update:modelValue"]);

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute("autofocus")) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>
<template>
  <div class="py-4 pb-0 tw-bg-white tw-overflow-hidden sm:tw-rounded-lg">
    <div class="wave-group pt-1">
      <input
        required="required"
        :type="type"
        class="input"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
      />
      <span class="bar"></span>
      <label
        class="label"
        :for="lableFor"
        :class="{ ar: getActiveLanguage() == 'ar' }"
      >
        <span class="label-char text-capitalize" style="--index: 0">{{
          label
        }}</span>
      </label>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wave-group {
  position: relative;
}

.wave-group .input {
  background-color: #f4f5f9;
  border: 2px solid #f4f5f9;
  font-size: 13px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 15px;
  color: #4f5d77;
  width: 100%;
  border-radius: 4px;
  display: block;
}

.wave-group .input:focus {
  outline: none;
  box-shadow: none;
}

.wave-group .label {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 15px;
  display: flex;

  &.ar {
    right: 10px;
    left: 0;
    top: 10px;
  }
}

.wave-group .label-char {
  transition: 0.2s ease all;
  transition-delay: calc(var(--index) * 0.05s);
}

.wave-group .input:focus ~ label .label-char,
.wave-group .input:valid ~ label .label-char {
  transform: translateY(-35px);
  font-size: 14px;
  color: rgb(0, 0, 0) e;
}

.wave-group .bar {
  position: relative;
  display: block;
  width: 100%;
}

.wave-group .bar:before,
.wave-group .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: $primary;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.wave-group .bar:before {
  left: 50%;
}

.wave-group .bar:after {
  right: 50%;
}

.wave-group .input:focus ~ .bar:before,
.wave-group .input:focus ~ .bar:after {
  width: 50%;
}
</style>
